import React from 'react';
import { Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

const Name: React.FC = () => {
  return (
    <>
      <Typography className="title">
        Qual <strong>empresa</strong> você quer indicar?
      </Typography>
      <div className="input">
        <Typography className="label">Escreva o nome da empresa.</Typography>
        <Field
          name="name"
          component={TextField}
          fullWidth
          autoFocus
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
    </>
  );
};

export default Name;
