import React, { useCallback, useState } from 'react';
import {
  Card,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { FaEye, FaTimes } from 'react-icons/fa';

import { useStyles } from './styles';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import api from '../../services/api';
import { getDateBr, getCurrencyBr } from '../../utils/format';
import { WithdrawPaymentDTO } from '../../dtos/WithdrawDTO';

interface WithdrawsDetailsProps {
  withdraw: WithdrawPaymentDTO;
}

const WithdrawDetails: React.FC<WithdrawsDetailsProps> = ({ withdraw }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<WithdrawPaymentDTO | null>(null);
  const [paymentMessage, setPaymentMesage] = useState<string | null>(null);
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const classes = useStyles();

  const handleDetailsOpen = useCallback(() => {
    startLoading();
    api
      .get<WithdrawPaymentDTO>(`/api/withdraw-payments/${withdraw.id}`)
      .then((result) => {
        setData(result.data);
        if (result.data.status !== 0) {
          setPaymentMesage(
            result.data.status === 3 ? 'Pago' : 'Previsão de pagamento'
          );
        }
      })
      .catch((error) => {
        showApiError(error, 'Erro carregando os dados da transação');
      })
      .finally(() => endLoading());

    setIsOpen(true);
  }, [startLoading, withdraw.id, showApiError, endLoading]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Card className={classes.circleCard} variant="elevation">
        <IconButton
          className="circle-button"
          onClick={() => handleDetailsOpen()}
        >
          <FaEye size={15} />
        </IconButton>
      </Card>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className={classes.detailsContainer}
      >
        <Typography component="div" className="withdrawId">
          ID: {withdraw.id}
        </Typography>
        <div>
          <IconButton onClick={handleClose} className={classes.closeBtn}>
            <FaTimes />
          </IconButton>
        </div>
        <Typography component="div" className={classes.title}>
          Detalhe do saque
        </Typography>
        <DialogContent>
          <Card className="detailsTableHeader">
            <Typography component="div">
              {getCurrencyBr(data?.total || 0)}
            </Typography>
            {paymentMessage && (
              <Typography>
                {paymentMessage} {data ? getDateBr(data.payment_date) : '-'}
              </Typography>
            )}
          </Card>
          <Table>
            <TableBody>
              {data ? (
                data.wallet_transactions.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {getDateBr(item.payment_liberate_date)}
                    </TableCell>
                    <TableCell>
                      {item.indication?.name} -&nbsp;
                      <span className="installment">{item.installment}/2</span>
                    </TableCell>
                    <TableCell>{getCurrencyBr(item.value)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>Sem resultados</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WithdrawDetails;
