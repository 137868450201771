import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  iconButton: {
    margin: 0,
    marginLeft: 8,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  tooltip: {
    fontSize: '13px',
    color: '#BCBCBC',
  },
});
