import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TermsContent from '../partials/Legal/Terms';

const useStyles = makeStyles({
  container: {
    padding: '15px 44px',
  },
});

const Terms: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TermsContent />
    </div>
  );
};

export default Terms;
