import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 389,
    maxWidth: '95%',
    padding: 50,

    '& .btn': {
      fontSize: '15px',
      fontWeight: 700,
      color: theme.palette.background.paper,
      border: `2px solid ${theme.palette.background.paper}`,

      '&.btn-filled': {
        border: `2px solid ${theme.palette.background.paper}`,
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        margin: '12px 0 24px',
      },

      '&.btn-link': {
        background: 'transparent',
        border: 0,
        textDecoration: 'underline',
      },
    },
  },
  text: {
    fontSize: '25px',
    color: theme.palette.background.paper,
    lineHeight: 1.7,
    marginBottom: 24,
  },
}));
