import React from 'react';
import { Card, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { IndicationFullDTO } from '../../dtos/IndicationDTO';

interface StatusProps {
  indication: IndicationFullDTO;
}

const Status: React.FC<StatusProps> = ({ indication }) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.container}
      style={{
        backgroundColor:
          Object.keys(indication).length > 0
            ? `#${indication.status_transactions[0].indication_status.color}`
            : undefined,
      }}
    >
      <div className={classes.statusContainer}>
        <Typography className={classes.statusTitle}>Status</Typography>
        <Typography className={classes.statusText}>
          {Object.keys(indication).length > 0
            ? indication.status_transactions[0].indication_status.name_app
            : null}
        </Typography>
      </div>
    </Card>
  );
};

export default Status;
