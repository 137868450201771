import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiInputAdornment-root': {
      cursor: 'pointer',
      color: '#CDD4D9',
    },
  },
  container: {
    width: 392,
    margin: '0 auto',
    padding: 40,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: 40,
    marginBottom: 60,
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: 30,
  },
  field: {
    marginBottom: 14,
  },
  loginButton: {
    padding: 20,
    fontSize: '18px',
    fontWeight: 700,
    marginTop: 40,
  },
  forgotPassword: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    textDecoration: 'none',
    marginTop: 15,
  },
  logonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    margin: '30px 0',
  },
  logonText: {
    fontSize: '11px',
    fontWeight: 600,
  },
  logonButton: {
    background: theme.palette.secondary.main,
    padding: '4px 15px',
    fontSize: '13px',
    fontWeight: 700,
  },
  socialLoginSep: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    marginBottom: 22,

    '& .line': {
      height: '1px',
      border: 0,
      borderBottom: '1px solid #707070',
      flex: '1',
    },

    '& .title': {
      color: '#000',
      margin: '0 30px',
      fontSize: '10px',
    },
  },
  socialLoginContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .sl-text': {
      fontSize: '10px',
      margin: '0 15px',
    },
  },
  socialLoginItem: {
    borderRadius: '50%',
    width: 38,
    height: 38,

    '& .social-btn': {
      width: '100%',
      height: '100%',
      background: 'transparent',
      border: 0,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },

    '& img': {
      width: 22,
      height: 22,
    },
  },
}));
