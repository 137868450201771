import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';
import Image from '../../../assets/tutorial/imagem_tutorial_03.png';

const Page3: React.FC<{ width: number }> = ({ width }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ minWidth: `${width}px` }}>
      <div className={classes.imageContainer}>
        <img src={Image} alt="Imagem do tutorial" className={classes.image} />
      </div>
      <div className={classes.titleContainer}>
        <Typography component="h1" className={classes.title}>
          SUAS INDICAÇÕES
        </Typography>
        <div className={classes.division} />
      </div>
      <Typography className={classes.text}>
        Na guia “Suas Indicações” veja o andamento da indicação feita. As
        informações do contato da empresa e o valor do acordo estão nessa guia.
      </Typography>
    </div>
  );
};

export default Page3;
