import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';

import { useStyles } from './styles';
import { FormProps } from './props';
import { useAuth } from '../../context/auth';
import { useLocalization } from '../../context/localization';

const validationSchema = Yup.object().shape({
  city_id: Yup.string().required('A cidade é obrigatória'),
  state_id: Yup.string().required('O estado é obrigatório'),
});

const AddressForm: React.FC<FormProps> = ({ onSubmit }) => {
  const { user } = useAuth();
  const { states, cities, getStates, getCities, resetCities } =
    useLocalization();
  const classes = useStyles();

  useEffect(() => {
    if (states.length === 0) {
      getStates();
    }
  }, [states, getStates]);

  useEffect(() => {
    if (user.address?.city?.state_id) {
      getCities(user.address.city.state_id);
    }
  }, [user, getCities]);

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      const data = {
        city_id: values.city_id.toString(),
        state_id: values.state_id.toString(),
      };

      onSubmit({
        data,
        method: 'put',
        url: `/api/address/${user.address_id}`,
        successMessage: 'Endereço atualizado com sucesso',
        errorMessage: 'Erro atualizando seu endereço',
      });
    },
    [onSubmit, user]
  );

  const formik = useFormik({
    initialValues: {
      city_id: user.address?.city_id || '',
      state_id: user.address?.city?.state_id || '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleSelectedState = useCallback(
    async (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      resetCities();
      const stateId = event.target.value as string;
      formik.handleChange(event);
      getCities(parseInt(stateId, 10));
    },
    [formik, getCities, resetCities]
  );

  return (
    <>
      <Typography className={classes.formTitle}>Endereço</Typography>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <TextField
          id="state_id"
          name="state_id"
          label="Estado"
          select
          className={classes.field}
          variant="outlined"
          fullWidth
          autoComplete="new-state"
          value={formik.values.state_id}
          error={formik.touched.state_id && !!formik.errors.state_id}
          helperText={formik.touched.state_id && formik.errors.state_id}
          onChange={handleSelectedState}
          onBlur={formik.handleBlur}
        >
          {states.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="city_id"
          name="city_id"
          label="Cidade"
          select
          className={classes.field}
          variant="outlined"
          fullWidth
          autoComplete="new-city"
          value={formik.values.city_id}
          error={formik.touched.city_id && !!formik.errors.city_id}
          helperText={formik.touched.city_id && formik.errors.city_id}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {cities.length === 0 ? (
            <MenuItem value="">Selecione um estado primeiro</MenuItem>
          ) : (
            cities.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))
          )}
        </TextField>
        <Box marginTop={3} textAlign="center">
          <Button type="submit" variant="contained" color="primary">
            Salvar alterações
          </Button>
        </Box>
      </form>
    </>
  );
};

export default AddressForm;
