import React, { useCallback, useState } from 'react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { FaRegEdit, FaTimes } from 'react-icons/fa';

import { useStyles } from './styles';
import ContactForm, { ValuesProps } from './ContactForm';
import { ContactDTO } from '../../dtos/IndicationDTO';

interface ContactEditProps {
  contact: ContactDTO;
  onUpdate: () => void;
}

const ContactEdit: React.FC<ContactEditProps> = ({ contact, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const initialValues: ValuesProps = {
    name: contact.name,
    email: contact.email,
    phone: contact.phone,
    occupation: contact.occupation,
    contact_date: contact.contact_date,
    shift_to_contact: contact.shift_to_contact,
    observation: contact.observation,
  };

  return (
    <>
      <IconButton title="Editar" onClick={handleOpen}>
        <FaRegEdit size={18} />
      </IconButton>
      <Dialog open={isOpen}>
        <div>
          <IconButton onClick={handleClose} className={classes.closeBtn}>
            <FaTimes />
          </IconButton>
        </div>
        <DialogContent>
          <ContactForm
            id={contact.id}
            initialValues={initialValues}
            onUpdate={onUpdate}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactEdit;
