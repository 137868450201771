import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    textAlign: 'justify',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '20px !important',
  },
  chapter: {
    fontSize: '1.2rem',
    fontWeight: 700,
    textAlign: 'left',
    marginTop: 15,
    marginBottom: '10px !important',
  },
});
