/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { useStyles } from './styles';
import { FormProps } from './props';
import { useAuth } from '../../context/auth';

const AvatarEdition: React.FC<FormProps> = ({ onSubmit }) => {
  const [newAvatar, setNewAvatar] = useState<string | null>(null);
  const [cropper, setCropper] = useState<any>();
  const { user } = useAuth();
  const classes = useStyles();

  const handleImageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const { files } = event.target;
      if (!files?.length) return;
      const reader = new FileReader();
      reader.onload = () => {
        setNewAvatar(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
    },
    []
  );

  const handleSubmit = useCallback((): void => {
    const cropped =
      cropper !== 'undefined' ? cropper.getCroppedCanvas().toDataURL() : null;
    if (cropped) {
      const data = {
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone,
        image: cropped,
      };

      onSubmit({
        url: `/api/user/${user.id}`,
        method: 'put',
        data,
        successMessage: 'Avatar atualizado com sucesso',
        errorMessage: 'Erro atualizando o avatar',
      });
    }
  }, [cropper, user, onSubmit]);

  return (
    <div className={classes.avatarEdition}>
      <Typography className="title">Atualizar avatar</Typography>
      <Box display="flex" justifyContent="center" mb={3} position="relative">
        <Typography component="div" className="preview-text">
          Vista previa
        </Typography>
        <div className="img-preview" style={{ height: 200, width: 200 }} />
      </Box>
      <div style={{ textAlign: 'center' }}>
        <label htmlFor="avatar-file">
          <input
            type="file"
            accept="image/*"
            id="avatar-file"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <Button variant="contained" color="primary" component="div">
            Selecionar imagem
          </Button>
        </label>
      </div>
      {newAvatar && (
        <>
          <div className="cropper-container">
            <Cropper
              style={{ height: 300, width: 300 }}
              aspectRatio={1}
              cropBoxResizable={false}
              preview=".img-preview"
              className="cropper"
              src={newAvatar}
              viewMode={1}
              zoomable
              zoomOnTouch={false}
              zoomOnWheel
              dragMode="move"
              guides
              minCropBoxHeight={150}
              minCropBoxWidth={150}
              background={false}
              responsive
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </div>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Atualizar avatar
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};

export default AvatarEdition;
