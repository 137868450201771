/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useCallback } from 'react';

import { useToast } from './toast';
import ApiError from '../utils/ApiError';

interface ErrorHandleContextData {
  showApiError(error: any, defaultMesage: string): void;
}

const ErrorHandleContext = createContext<ErrorHandleContextData>(
  {} as ErrorHandleContextData
);

const ErrorHandleProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const showError = useCallback(
    (message: string | string[]) => {
      addToast({ message, type: 'error' });
    },
    [addToast]
  );

  const showApiError = useCallback(
    (error: any, defaultMesage: string) => {
      const errorMsg =
        error instanceof ApiError ? error.getMessage() : defaultMesage;
      showError(errorMsg);
    },
    [showError]
  );

  return (
    <ErrorHandleContext.Provider value={{ showApiError }}>
      {children}
    </ErrorHandleContext.Provider>
  );
};

const useErrorHandle = (): ErrorHandleContextData => {
  const context = useContext(ErrorHandleContext);

  if (!context) {
    throw new Error('useErrorHandle must be used inside a ErrorHandleProvider');
  }

  return context;
};

export { useErrorHandle, ErrorHandleProvider };
