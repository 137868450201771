import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import { theme } from './styles/theme';
import Routes from './routes';
import CustomProviders from './context';

const customTheme = createTheme(theme);

const App: React.FC = () => (
  <ThemeProvider theme={customTheme}>
    <CustomProviders>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </CustomProviders>
  </ThemeProvider>
);

export default App;
