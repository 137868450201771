import React, { createContext, useContext, useCallback, useState } from 'react';

import Terms from '../pages/partials/Legal/Terms';
import Privacy from '../pages/partials/Legal/Privacy';
import LegalView from '../components/LegalView/index';

interface LegalContextData {
  closePrivacy(): void;
  openPrivacy(): void;
  closeTerms(): void;
  openTerms(): void;
}

const LegalContext = createContext<LegalContextData>({} as LegalContextData);

const LegalProvider: React.FC = ({ children }) => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const closePrivacy = useCallback(() => {
    setIsPrivacyOpen(false);
  }, []);

  const openPrivacy = useCallback(() => {
    setIsPrivacyOpen(true);
  }, []);

  const closeTerms = useCallback(() => {
    setIsTermsOpen(false);
  }, []);

  const openTerms = useCallback(() => {
    setIsTermsOpen(true);
  }, []);

  return (
    <LegalContext.Provider
      value={{ closePrivacy, openPrivacy, closeTerms, openTerms }}
    >
      {children}
      <LegalView open={isPrivacyOpen} onClose={closePrivacy}>
        <Privacy />
      </LegalView>
      <LegalView open={isTermsOpen} onClose={closeTerms}>
        <Terms />
      </LegalView>
    </LegalContext.Provider>
  );
};

const useLegal = (): LegalContextData => {
  const context = useContext(LegalContext);

  if (!context) {
    throw new Error('useLegal must be used within a LegalProvider');
  }

  return context;
};

export { LegalProvider, useLegal };
