/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';
import { UserDTO } from '../dtos/UserDTO';

interface AuthState {
  access_token: string;
  user: UserDTO;
}

interface SignInData {
  login: string;
  password: string;
}

export interface SocialSignInData {
  grant_type: string;
  client_id: string;
  client_secret: string;
  provider: 'facebook' | 'google';
  access_token: string;
  trk?: string;
}

interface AuthContextData {
  user: UserDTO;
  signIn(credentials: SignInData): Promise<void>;
  socialSignIn(info: SocialSignInData): Promise<void>;
  signOut(): void;
  updateUser(user: UserDTO): void;
  storeData(token: string): Promise<void>;
  getUser(): Promise<void>;
  checkAuth(): boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const access_token = localStorage.getItem('@IndikeGanhe:token');
    const user = localStorage.getItem('@IndikeGanhe:user');

    if (access_token && user) {
      return { access_token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const getUser = useCallback(async () => {
    const access_token = localStorage.getItem('@IndikeGanhe:token');

    if (!access_token) return;

    const userResponse = await api.get<UserDTO>('/api/auth/user', {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    const user = userResponse.data;

    localStorage.setItem('@IndikeGanhe:user', JSON.stringify(user));

    setData({ access_token, user });
  }, []);

  const storeData = useCallback(
    async (access_token: string) => {
      localStorage.setItem('@IndikeGanhe:token', access_token);

      getUser();
    },
    [getUser]
  );

  const signIn = useCallback(
    async ({ login, password }) => {
      const response = await api.post<AuthState>('/api/auth/login', {
        login,
        password,
      });

      const { access_token } = response.data;

      await storeData(access_token);
    },
    [storeData]
  );

  const socialSignIn = useCallback(
    async (socialData: SocialSignInData) => {
      const response = await api.post('/api/auth/social/token', socialData);

      const { access_token } = response.data;

      await storeData(access_token);
    },
    [storeData]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@IndikeGanhe:token');
    localStorage.removeItem('@IndikeGanhe:user');

    setData({} as AuthState);
  }, []);

  const updateUser = (user: UserDTO): void => {
    localStorage.setItem('@IndikeGanhe:user', JSON.stringify(user));
    Object.assign(data, { user });
  };

  const checkAuth = (): boolean => {
    return !!data.access_token;
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        socialSignIn,
        signOut,
        updateUser,
        storeData,
        getUser,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { useAuth, AuthProvider };
