import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  menuContainer: {
    width: 292,
    height: '100vh',
    background: theme.palette.background.paper,
    position: 'relative',
  },
  menuButton: {
    color: theme.palette.background.paper,
  },
  avatarContainer: {
    margin: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    marginTop: 8,
    fontWeight: 600,
  },
  list: {
    marginTop: 50,
    padding: 30,
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },

    '& .MuiListItem-button:hover': {
      borderRadius: 12,
    },
  },
  listIcon: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: 24,
    justifyContent: 'center',
    minWidth: 30,

    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  listText: {
    '& .MuiListItemText-primary': {
      marginLeft: 15,
      textTransform: 'uppercase',
      fontWeight: 600,
    },
  },
  btnContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 40,
    [theme.breakpoints.up('md')]: {
      bottom: 10,
    },
  },
  btn: {
    // margin: 40,
  },
  activeMenu: {
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main,
    },
  },
  drawer: {
    width: 300,
    flexShrink: 0,
  },
  permanentDrawer: {
    '& .MuiDrawer-paper': {
      paddingTop: 80,
      paddingBottom: 30,
    },
  },
}));
