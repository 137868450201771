import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { useTutorial } from '../../context/tutorial';

const Welcome: React.FC = () => {
  const history = useHistory();
  const { openTutorial } = useTutorial();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.text}>
          <strong>Parabéns.</strong>
          <br />
          Agora você faz parte do <strong>IndikeGanhe</strong>!
        </Typography>
        <Button
          variant="outlined"
          className="btn btn-cleared"
          fullWidth
          size="large"
          onClick={openTutorial}
        >
          Tutorial
        </Button>
        <Button
          variant="contained"
          className="btn btn-filled"
          fullWidth
          onClick={() => history.push('/indicar')}
        >
          Indicar
        </Button>
        <Button
          className="btn btn-link"
          fullWidth
          onClick={() => history.push('/')}
        >
          Ir para o painel
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
