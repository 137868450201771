import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';

import { useStyles } from './styles';
import Name from './Name';
import Email from './Email';
import Phone from './Phone';
import Address from './Address';
import Cpf from './Cpf';
import Password from './Password';
import Confirm from './Confirm';
import CloseBtn from '../../components/CloseBtn';
import { FormStepper, FormStep } from '../../components/FormSteps';
import {
  checkCpfAvailability,
  cpfValidator,
  emailValidator,
  phoneValidator,
} from '../../utils/validators';
import api from '../../services/api';
import { useToast } from '../../context/toast';
import { useAuth } from '../../context/auth';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { cleanNumericString } from '../../utils/format';

const Logon: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const { search } = useLocation();
  const { storeData } = useAuth();
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const classes = useStyles();

  const trk = new URLSearchParams(search).get('trk');

  const handleSubmit = useCallback(
    async ({
      firstname,
      lastname,
      email,
      phone,
      document,
      password,
      password_confirmation,
      city,
      accepted_terms,
    }: FormikValues) => {
      const data = {
        firstname,
        lastname,
        email,
        phone,
        document: cleanNumericString(document),
        password,
        password_confirmation,
        accepted_terms,
        address: {
          city_id: city,
        },
      };

      if (trk) {
        Object.assign(data, { trk });
      }

      startLoading();
      api
        .post<{ access_token: string }>('/api/auth/register', data)
        .then((result) => {
          addToast({
            message: 'Cadastro completado com sucesso',
            type: 'success',
          });
          storeData(result.data.access_token).then(() => {
            history.push('/welcome');
          });
        })
        .catch((error) => {
          showApiError(error, 'Erro no cadastro');
        })
        .finally(() => {
          endLoading();
        });
    },
    [trk, startLoading, addToast, storeData, history, showApiError, endLoading]
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <CloseBtn />
        <FormStepper
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            document: '',
            city: '',
            state: '',
            password: '',
            password_confirmation: '',
            accepted_terms: false,
          }}
          onSubmit={async (values) => {
            handleSubmit(values);
          }}
        >
          <FormStep
            validationSchema={Yup.object().shape({
              firstname: Yup.string().required('Nome é obrigatório'),
              lastname: Yup.string().required('Sobrenome é obrigatório'),
            })}
          >
            <Name />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required('E-mail é obrigatório')
                .test('Email', 'E-mail inválido', (value) =>
                  emailValidator(value)
                ),
            })}
          >
            <Email />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              phone: Yup.string()
                .required('Telefone obrigatório')
                .test('Phone', 'Telefone inválido', (value) =>
                  phoneValidator(value)
                ),
            })}
          >
            <Phone />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              state: Yup.string().required('O estado é obrigatório'),
              city: Yup.string().required('A cidade é obrigatória'),
            })}
          >
            <Address />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              document: Yup.string()
                .required('CPF é obrigatório')
                .test('Cpf', 'Cpf inválido', cpfValidator)
                .test(
                  'UnusedCPF',
                  'Este documento já foi cadastrado',
                  checkCpfAvailability
                ),
            })}
          >
            <Cpf />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required('Senha obrigatória')
                .min(8, 'Mínimo de 8 caracteres'),
              password_confirmation: Yup.string()
                .required('A confirmação da senha é requerida')
                .oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
            })}
          >
            <Password />
          </FormStep>
          <FormStep
            validationSchema={Yup.object().shape({
              accepted_terms: Yup.boolean().oneOf(
                [true],
                'Deve aceptar os termos de uso'
              ),
            })}
          >
            <Confirm />
          </FormStep>
        </FormStepper>
      </div>
    </div>
  );
};

export default Logon;
