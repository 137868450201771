import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  indicatorsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    margin: '0 2px',
  },
  statusTitle: {
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
});
