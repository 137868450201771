import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { IconType } from 'react-icons';

import { useStyles } from './styles';

interface MenuItemProps {
  title: string;
  destination: string;
  icon: IconType | React.FunctionComponent;
  onClose: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  destination,
  icon: Icon,
  title,
  onClose,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();

  const handleClick = useCallback(() => {
    history.push(destination);
    onClose();
  }, [history, destination, onClose]);

  return (
    <ListItem
      button
      onClick={handleClick}
      className={destination === pathname ? classes.activeMenu : undefined}
    >
      <ListItemIcon className={classes.listIcon}>
        <Icon />
      </ListItemIcon>
      <ListItemText className={classes.listText} primary={title} />
    </ListItem>
  );
};

export default MenuItem;
