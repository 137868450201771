import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 389,
    maxWidth: '95%',
    marginTop: 50,
    marginBottom: 15,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'unset',
    },
  },
  header: {
    maxWidth: 389,
    margin: '0 auto',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  searchBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontSize: 14,
    borderRadius: 5,
    padding: 8,
  },
  text: {
    color: '#77838F',
    fontSize: '13px',
    textAlign: 'center',
    marginTop: 12,
    marginBottom: 30,
  },
  helpCard: {
    background: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
    },
    padding: 40,
    marginBottom: 15,
    cursor: 'pointer',
  },
  cardIcon: {
    marginRight: 30,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: 50,
      width: 50,
    },
  },
  cardText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  cardTitle: {
    color: '#1E2022',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  cardSubtitle: {
    color: '#77838F',
    fontSize: '12px',
  },
  helpPageContainer: {
    width: 389,
    maxWidth: '95%',
    marginTop: 40,
    marginBottom: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: 1000,
    },
  },
  resultContainer: {
    '& .result-item': {
      display: 'flex',
      flexDirection: 'column',

      '&+div': {
        borderTop: '1px solid #999,',
      },
    },
    '& .result-title': {
      fontSize: '16px',
      fontWeight: 700,
    },
    '& .result-match': {
      fontSize: '14px',
      fontStyle: 'italic',
    },
    '& .matched': {
      background: 'yellow',
    },
  },
  pageCard: {
    background: theme.palette.background.paper,
    marginTop: 40,
    padding: 40,
    '& .header': {
      display: 'flex',
      marginBottom: 30,
      '& img': {
        marginRight: 10,
      },
    },
    '& .title': {
      color: '#1E2022',
      fontSize: '20px',
      fontWeight: 700,
      margin: 0,
    },
    '& .subtitle': {
      color: '#1E2022',
      fontSize: '14px',
      fontStyle: 'italic',
      margin: 0,
    },
    '& p': {
      lineHeight: 1.8,
      color: '#77838F',
      fontSize: '15px',
      marginBottom: 20,
    },
    '& ol': {
      margin: 0,
      paddingLeft: 15,
    },
    '& li': {
      lineHeight: 1.5,
      color: '#77838F',
      fontSize: '15px',
      marginBottom: 10,
    },
  },
}));
