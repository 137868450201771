import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import CloseBtn from '../../components/CloseBtn';
import { useTutorial } from '../../context/tutorial';

const Start: React.FC = () => {
  const history = useHistory();
  const { openTutorial } = useTutorial();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <CloseBtn destination="/" />
        <Typography className={classes.text}>
          Olá, seja <strong>bem-vindo</strong> ao programa de indicação{' '}
          <strong>IndikeGanhe</strong>.
        </Typography>
        <Button
          variant="contained"
          className={classes.btnFilled}
          fullWidth
          size="large"
          onClick={() => history.push('/nova-indicacao')}
        >
          Vamos indicar?
        </Button>
        <Button
          variant="outlined"
          className={classes.btnOutlined}
          fullWidth
          size="large"
          onClick={openTutorial}
        >
          Aprenda a indicar
        </Button>
      </div>
    </div>
  );
};

export default Start;
