/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import SocialLogin, { Props } from 'react-social-login';

class SocialButton extends React.Component<Props> {
  render(): JSX.Element {
    const { children, triggerLogin, ...rest } = this.props;

    return (
      <button
        {...rest}
        type="button"
        onClick={triggerLogin}
        className="social-btn"
      >
        {children}
      </button>
    );
  }
}

export default SocialLogin(SocialButton);
