/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, ListItem, TextField, Typography } from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';

import { useStyles } from './styles';
import { pages, PageName } from './pagesList';
import { HelpFileProps } from './HelpView';
import { useAuth } from '../../context/auth';

interface SearchResult {
  page: PageName;
  pageTitle: string;
  match?: string;
}

const contentParse = (query: string, target: string): string | null => {
  const parsed = target.split(' ');

  const idx = parsed.findIndex(
    (item) => item.toLocaleLowerCase() === query.toLocaleLowerCase()
  );

  if (idx === -1) return null;

  const start = idx < 10 ? 0 : idx - 10;
  const end = idx >= parsed.length - 10 ? parsed.length : idx + 10;
  const matchPrev = parsed.slice(start, idx);
  const prefix = start !== 0 ? '... ' : '';
  const matchNext = parsed.slice(idx + 1, end + 1);
  const suffix = end !== parsed.length ? ' ...' : '';
  const fragments = [
    ...matchPrev,
    `<span class="matched">${parsed[idx]}</span>`,
    ...matchNext,
  ];
  return prefix + fragments.join(' ') + suffix;
};

const searchFn = (query: string): SearchResult[] => {
  const matches: SearchResult[] = pages.map((page) => {
    const file: HelpFileProps = require(`./${page}.json`);
    const searchedTitle = contentParse(query, file.title);
    if (searchedTitle) {
      return { page, pageTitle: searchedTitle };
    }

    const searchedSubtitle = contentParse(query, file.subtitle);
    if (searchedSubtitle) {
      return { page, pageTitle: file.title, match: searchedSubtitle };
    }

    for (let i = 0; i < file.contents.length; i += 1) {
      const item = file.contents[i];
      if (item.content) {
        const contentParagraph = contentParse(query, item.content);
        if (contentParagraph) {
          return { page, pageTitle: file.title, match: contentParagraph };
        }
      }
      if (item.items && item.items.length) {
        for (let j = 0; j < item.items.length; j += 1) {
          const contentItem = contentParse(query, item.items[j]);
          if (contentItem) {
            return { page, pageTitle: file.title, match: contentItem };
          }
        }
      }
    }

    return {} as SearchResult;
  });

  return matches;
};

const Search: React.FC = () => {
  const [query, setQuery] = useState('');
  const [queryResult, setQueryResult] = useState<SearchResult[]>([]);
  const { checkAuth } = useAuth();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const search = setTimeout(() => {
      if (query) {
        const result = searchFn(query);
        setQueryResult(result.filter((item) => Object.keys(item).length));
      }
    }, 500);

    return () => clearTimeout(search);
  }, [query]);

  const handleSearch = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(ev.currentTarget.value);
    },
    []
  );

  const handleItemClick = useCallback(
    (page: string) => {
      const basePath = checkAuth() ? 'ajuda' : 'duvidas-frequentes';
      history.push(`/${basePath}/${page}`);
    },
    [checkAuth, history]
  );

  return (
    <>
      <TextField
        name="help"
        variant="outlined"
        fullWidth
        placeholder="Palavra chave"
        InputProps={{
          endAdornment: (
            <div className={classes.searchBtn}>
              <FaSearch />
            </div>
          ),
        }}
        onChange={handleSearch}
      />
      <Typography className={classes.text}>
        Tópicos de ajuda populares: indicar, dados, sacar.
      </Typography>
      <Box className={classes.resultContainer}>
        {queryResult.map((item) => (
          <ListItem button onClick={() => handleItemClick(item.page)}>
            <div className="result-item">
              <Typography
                className="result-title"
                dangerouslySetInnerHTML={{ __html: item.pageTitle }}
              />
              {item.match && (
                <Typography
                  className="result-match"
                  dangerouslySetInnerHTML={{ __html: item.match }}
                />
              )}
            </div>
          </ListItem>
        ))}
      </Box>
    </>
  );
};

export default Search;
