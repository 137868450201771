export const cleanNumericString = (value: string): string => {
  return value.replace(/\D/g, '');
};

export const fullName = (firstname: string, lastname: string): string => {
  return `${firstname} ${lastname}`;
};

export const getIsoDate = (date: Date): string => {
  const iso = date.toISOString();
  return iso.substring(0, iso.indexOf('T'));
};

export const getDateBr = (value: string | null): string => {
  if (!value) return '';
  const formattedValue =
    value.split('T').length === 1 ? value.replace(/-/g, '/') : value;
  const date = new Date(formattedValue);
  return date.toLocaleDateString('pt-BR');
};

export const getCurrencyBr = (value: string | number): string => {
  const convertedValue = typeof value === 'string' ? parseFloat(value) : value;
  const currency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return currency.format(convertedValue);
};

export const getChartDateLabel = (value: string): string => {
  const date = new Date(value);
  const year = date.toLocaleDateString('default', { year: '2-digit' });
  const month = date.toLocaleDateString('default', { month: 'short' });
  const formattedMonth = month.replace('.', '').toLocaleUpperCase();
  return `${formattedMonth} / ${year}`;
};

export const phoneFormat = (input: string): string => {
  const phone = input.replace(/\D/g, '');
  const isEven = phone.length % 2 === 0;
  const ddd = isEven ? phone.substr(-10, 2) : phone.substr(-11, 2);
  const firstNumber = isEven ? phone.substr(-8, 4) : phone.substr(-9, 5);
  const secondNumber = phone.substr(-4);
  return `(${ddd}) ${firstNumber}-${secondNumber}`;
};
