import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';
import Image from '../../../assets/tutorial/imagem_tutorial_01.png';

const Page1: React.FC<{ width: number }> = ({ width }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ minWidth: `${width}px` }}>
      <div className={classes.imageContainer}>
        <img
          src={Image}
          alt="Imagem do tutorial"
          className={classes.image}
          // style={{
          //   width: '100%',
          //   marginTop: 30,
          //   marginBottom: 0,
          // }}
        />
      </div>
      <div className={classes.titleContainer}>
        <Typography component="h1" className={classes.title}>
          COMO INDICAR
        </Typography>
        <div className={classes.division} />
      </div>
      <Typography className={classes.text}>
        Na página inicial do painel, clique no botão indicar. Agora preencha os
        dados da sua empresa indicada. Pronto, sua indicação foi feita com
        sucesso.
      </Typography>
    </div>
  );
};

export default Page1;
