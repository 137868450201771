import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { FaMapMarkerAlt, FaUserCog } from 'react-icons/fa';
import { AiOutlineBank } from 'react-icons/ai';
import { RiImageEditLine } from 'react-icons/ri';

import { useStyles } from './styles';
import { Editing } from './ProfileEdit';
import Avatar from '../../components/Avatar';
import BackLink from '../../components/BackLink';
import { useAuth } from '../../context/auth';
import { fullName } from '../../utils/format';
import LowerCtaDesktop from '../../assets/lower_cta_desktop.png';

const Profile: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();
  const fullname = fullName(user.firstname, user.lastname);

  const handleChangeEdition = useCallback(
    (section: Editing) => {
      history.push(`/perfil/${section}`);
    },
    [history]
  );

  return (
    <div className={classes.pageContainer}>
      <Box className={classes.backBtn}>
        <BackLink floating={false} color="dark" />
      </Box>
      <Grid container spacing={matches ? 3 : 0}>
        <Grid item xs={12} lg={6}>
          <Box className={classes.avatarContainer}>
            <div className={classes.avatarImgContainer}>
              <Avatar size={120} />
              <IconButton
                className={classes.avatarEditBtn}
                title="Trocar avatar"
                onClick={() => handleChangeEdition('avatar')}
              >
                <RiImageEditLine />
              </IconButton>
            </div>
            <Typography component="div" className={classes.name}>
              {fullname}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card
            className={classes.container}
            onClick={() => handleChangeEdition('dados')}
          >
            <Box className={classes.preview}>
              <FaUserCog className="preview-icon" />
              <Typography component="span">Meus Dados</Typography>
            </Box>
          </Card>
          <Card
            className={classes.container}
            onClick={() => handleChangeEdition('endereco')}
          >
            <Box className={classes.preview}>
              <FaMapMarkerAlt className="preview-icon" />
              <Typography component="span">Endereço</Typography>
            </Box>
          </Card>
          <Card
            className={classes.container}
            onClick={() => handleChangeEdition('conta')}
          >
            <Box className={classes.preview}>
              <AiOutlineBank className="preview-icon" />
              <Typography component="span">Dados bancários</Typography>
            </Box>
          </Card>
        </Grid>
        {matches && (
          <Grid item xs={12}>
            <img
              src={LowerCtaDesktop}
              alt="CTA"
              style={{ width: '100%', borderRadius: 5 }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Profile;
