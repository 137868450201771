import React, { createContext, useContext, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaTimes } from 'react-icons/fa';

import Page1 from '../pages/partials/Tutorial/page1';
import Page2 from '../pages/partials/Tutorial/page2';
import Page3 from '../pages/partials/Tutorial/page3';
import Page4 from '../pages/partials/Tutorial/page4';

const pagesQuantity = 4;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      width: 382,
      maxWidth: '95%',
      [theme.breakpoints.up('sm')]: {
        width: 600,
      },
      backgroundColor: '#0054F0',
      color: '#FFFFFF',
      opacity: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 25,
    },
    '& .MuiButton-label': {
      color: '#0054F0',
      fontWeight: 700,
    },
  },
  closeBtn: {
    position: 'absolute',
    top: 30,
    right: 30,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    zIndex: 100,

    '&:hover': {
      cursor: 'pointer',
    },

    '& .MuiTypography-root': {
      fontSize: '12px',
      fontWeight: 700,
      textTransform: 'uppercase',
      marginRight: 10,
    },
  },
  tutorialContainer: {
    overflowX: 'hidden',
    width: '100%',
  },
  pagesContainer: {
    display: 'flex',
    position: 'relative',
    transition: 'all 0.5s ease',
  },
  footer: {
    marginTop: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  pageIndicator: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': { background: '#FFFFFF', margin: '0 3px' },
    '& .activePage': { width: 6, height: 6 },
    '& .otherPage': { width: 3, height: 3 },
  },
}));

interface ContextData {
  isOpen: boolean;
  openTutorial(): void;
  closeTutorial(): void;
}

const TutorialContext = createContext<ContextData>({} as ContextData);

const TutorialProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const [page, setPage] = useState(1);
  const classes = useStyles();

  const openTutorial = useCallback(() => {
    setIsOpen(true);
    setPage(1);
  }, []);

  const closeTutorial = useCallback(() => {
    setIsOpen(false);
  }, []);

  const prevPage = useCallback(() => {
    if (page === 1) return;
    setPage(page - 1);
  }, [page]);

  const nextPage = useCallback(() => {
    if (page === pagesQuantity) {
      closeTutorial();
      return;
    }
    setPage(page + 1);
  }, [page, closeTutorial]);

  const getWidth = useCallback((element: HTMLDivElement) => {
    setTimeout(() => {
      if (element) {
        const style = window.getComputedStyle(element);
        const pl = parseInt(style.getPropertyValue('padding-left'), 10);
        const pr = parseInt(style.getPropertyValue('padding-right'), 10);
        setContentWidth(element.offsetWidth - pl - pr);
      }
    }, 200);
  }, []);

  return (
    <TutorialContext.Provider value={{ isOpen, openTutorial, closeTutorial }}>
      {children}
      <Dialog open={isOpen} onClose={closeTutorial} className={classes.root}>
        <Box className={classes.closeBtn} onClick={closeTutorial}>
          <Typography component="span">fechar</Typography>
          <FaTimes />
        </Box>
        <DialogContent ref={getWidth} className={classes.tutorialContainer}>
          {contentWidth && (
            <div
              className={classes.pagesContainer}
              style={{ left: -(page - 1) * (contentWidth + 200) }}
            >
              <Page1 width={contentWidth} />
              <Page2 width={contentWidth} />
              <Page3 width={contentWidth} />
              <Page4 width={contentWidth} />
            </div>
          )}
        </DialogContent>
        <div className={classes.footer}>
          <div className={classes.pageIndicator}>
            {Array(4)
              .fill('')
              .map((_, idx) => (
                <div
                  key={`tutorial_page_${idx.toString()}`}
                  className={idx === page - 1 ? 'activePage' : 'otherPage'}
                />
              ))}
          </div>
          {page > 1 ? (
            <Button variant="contained" onClick={prevPage}>
              Voltar
            </Button>
          ) : (
            <div />
          )}
          <Button variant="contained" onClick={nextPage}>
            {page === pagesQuantity ? 'Fechar' : 'Próximo'}
          </Button>
        </div>
      </Dialog>
    </TutorialContext.Provider>
  );
};

const useTutorial = (): ContextData => {
  const context = useContext(TutorialContext);

  if (!context) {
    throw new Error('useTutorial must be used within a TutorialProvider');
  }

  return context;
};

export { TutorialProvider, useTutorial };
