import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

const Terms: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        Termos e Condições Gerais do “Programa IndikeGanhe”
      </Typography>
      <Typography>
        Antes de prosseguir, é fundamental que você leia atentamente e
        compreenda todos os Termos que regulam a utilização da Plataforma e de
        seus benefícios, pois ao se cadastrar, você estará aceitando as nossas
        regras. Não se preocupe, suas informações e dados pessoais fornecidos
        serão armazenados em nossos sistemas, de forma confidencial e segura, e
        serão tratados conforme nossa Política de Privacidade.
      </Typography>
      <Typography className={classes.chapter}>1. INTRODUÇÃO</Typography>
      <Typography>
        <strong>1.1</strong> A Plataforma virtual IndikeGanhe foi desenvolvido e
        é mantido pela INOVADIG TRANSFORMAÇÃO EMPRESARIAL LTDA, pessoa jurídica
        de direito privado com sede na cidade de Gravataí/RS, na Avenida José
        Loureiro da Silva , nº 2025, sala 1801, bairro Centro, inscrita no
        CNPJ/MF sob nº 39.265.077/0001-29, por seu representante legal
        firmatário, doravante denominada “Inovadig”, que desenvolveu e explora a
        tecnologia digital inserida em seu domínio web – www.indikeganhe.com.br
        – “Site” e na “Plataforma” IndikeGanhe sendo essencial para a realização
        da prestação dos serviços a que se dispõe.
      </Typography>
      <Typography>
        <strong>1.2</strong> A Inovadig dedica-se, dentre outras atividades, à
        prestação de serviços a pessoas jurídicas, visando o desenvolvimento de
        softwares e sistemas web, além de aplicativos para dispositivos móveis
        por encomenda, de acordo com a solicitação de seus clientes;
      </Typography>
      <Typography>
        <strong>1.3</strong> O signatário da proposta de adesão eletrônica ao
        Programa IndikeGanhe, doravante denominado simples, genérica e
        individualmente “Indiker”, que deseja indicar para os clientes da
        Inovadig, doravante denominado ”Clientes Parceiros”, “Clientes
        Potenciais” interessados na contratação dos serviços dos mesmos;
      </Typography>
      <Typography>
        <strong>1.4</strong> O “Termo” estabelece a relação contratual entre a
        Inovadig e o Indiker que utiliza o Site e/ou Plataforma IndikeGanhe,
        estabelecendo as regras de utilização e as responsabilidades de cada
        parte, em conformidade com a legislação brasileira, incluindo as
        disposições da Lei Nº 12.965/14 (“Marco Civil da Internet”) e do Decreto
        Nº 8.771, de 11/05/16 (“Decreto”). A Inovadig se reserva o direito de
        alterar as regras a qualquer momento, no todo ou em parte, sendo que o
        Indiker deva se manter atualizado através do Site e/ou Plataforma.
      </Typography>
      <Typography>
        <strong>1.5</strong> Ao se cadastrar na Plataforma IndikeGanhe, você
        estará legalmente vinculado a todos os termos e condições aqui
        presentes. Caso tenha qualquer dúvida sobre o conteúdo, entre em contato
        com nosso suporte através do e-mail:{' '}
        <a href="mailto:contato@indikeganhe.com.br">
          contato@indikeganhe.com.br
        </a>
        .
      </Typography>
      <Typography>
        <strong>1.6</strong> Após a conclusão do cadastro, o Indiker terá um
        login e uma senha que são de uso pessoal e intransferível, e de uso
        exclusivo do Indiker, não podendo ser repassados a terceiros, assumindo,
        portanto, total responsabilidade por sua guarda e sigilo, com a
        obrigação de evitar que sejam utilizados por outras pessoas.
        Recomenda-se que o Indiker mude sua senha periodicamente, e que não
        utilize senhas que facilitem a adivinhação por terceiros, como números
        de telefone, endereço, datas de nascimento, etc…
      </Typography>
      <Typography className={classes.chapter}>2. PRÉ-REQUISITOS</Typography>
      <Typography>
        <strong>2.1</strong> Serão elegíveis como Indiker a participar do
        IndikeGanhe, as pessoas naturais formalizadas ou não como
        Microempreendedor Individual (MEI), na forma da Lei Complementar
        123/2006, com as alterações feitas pela Lei Complementar 128/2008.
      </Typography>
      <Typography>
        <strong>2.2</strong> O Indiker é responsável pelas informações e
        documentos fornecidos no seu cadastro e adesão ao presente Programa,
        afirmando serem verdadeiros e legítimos, tendo sido expedidos pelas
        autoridades ou repartições competentes, autorizando que os “Clientes
        Parceiros” utilizem tais dados cadastrais de contato (e-mail, telefone,
        WhatsApp) para envio de informações, relatórios, promoções e ofertas.
      </Typography>
      <Typography>
        <strong>2.3</strong> O Indiker poderá indicar amigos “Terceiros” para
        utilizarem o Programa, desta forma, a Inovadig poderá oferecer
        benefícios que serão conferidos de acordo com o regulamento da promoção
        vigente, sendo certo que o principal requisito é a constatação de que o
        Terceiro foi cadastrado através da indicação do Indiker em referência.
      </Typography>
      <Typography>
        <strong>2.3.1</strong> O regulamento da promoção poderá prever o envio
        de e-mails pelos Clientes Parceiros ao Terceiro, contendo o nome do
        Indiker que enviou a recomendação, não sendo permitido que um mesmo
        Terceiro tenha mais de um cadastro, pois caso isto ocorra, a Inovadig
        poderá inabilitar definitivamente todas as indicações do Indiker.
      </Typography>
      <Typography>
        <strong>2.4</strong> A atuação, pelo Indiker, fora dos parâmetros do
        Programa, o sujeitará às medidas legais cabíveis, além de se
        caracterizar como abuso de representação, dessa forma, o Indiker não
        está autorizado a oferecer produtos nem negociar valores, assim como,
        caso seja identificado uma reclamação falsa, fraudulenta ou de má-fé, o
        acesso do Indiker ao Programa poderá ser excluído, com a automática
        expiração de eventual saldo tanto de Promessa de Recompensa quanto de
        Saldo de Resgate.
      </Typography>
      <Typography className={classes.chapter}>3. INDICAÇÃO</Typography>
      <Typography>
        <strong>3.1</strong> O Indiker, utilizando-se da Plataforma, poderá
        indicar Clientes Potenciais, exclusivamente pessoas jurídicas que
        desejem contratar os Serviços disponibilizados pelos Clientes Parceiros,
        informando dados básicos do Cliente Potencial solicitados na própria
        Plataforma, fazer inclusive o contato inicial com o responsável de tal
        empresa, apresentando os Serviços prestados e solicitando aceite prévio
        de um futuro contato das equipes dos Clientes Parceiros.
      </Typography>
      <Typography>
        <strong>3.2</strong> Aceita a indicação, conforme seus próprios e
        exclusivos critérios de análise, o setor comercial do “Cliente Parceiro”
        entrará em contato com o Cliente Potencial, fazendo uma consultoria e
        ofertando as ferramentas e pacotes que melhor se adaptarem a realidade e
        necessidade do mesmo, sendo enviada a Proposta de Prestação de Serviços
        pelo “Cliente Parceiro” para efetivar a contratação dos Serviços.
      </Typography>
      <Typography className={classes.chapter}>
        4. PRÊMIO, BONIFICAÇÃO E PROMOÇÕES
      </Typography>
      <Typography>
        <strong>4.1</strong> O Prêmio é a forma utilizada pela Inovadig para
        incentivar e recompensar o Indiker pela indicação de um Cliente
        Potencial que efetivou o Contrato de Prestação de Serviços com um
        “Cliente Parceiro”, e será representado por: 50% (cinquenta por cento)
        do valor da franquia contratada em parcela única para o Indiker nível
        Prata, que possuir até 3 indicações fechadas; 60% (sessenta por cento)
        do valor da franquia contratada em parcela única para o Indiker nível
        Ouro, que possuir de 4 a 10 indicações fechadas; e 80% (oitenta por
        cento) do valor da franquia contratada em 2 (duas) parcelas para o
        Indiker nível Diamante, que possuir acima de 11 indicações fechadas,
        sendo 20% (vinte por cento) na semana do fechamento e 60% (sessenta por
        cento) após o primeiro faturamento, descrita na Proposta de Contratação
        de Prestação de Serviços, no item Serviços/Produtos.
      </Typography>
      <Typography>
        <strong>4.2</strong> Na Plataforma, após o Indiker indicar o Cliente
        Potencial, e o “Cliente Parceiro” iniciar a negociação, será incluída
        uma Promessa de Recompensa dividida em uma parcela, ou duas quando se
        tratar da bonificação do Indiker Diamante, que serão devidas quando esse
        tiver efetivamente contratado os Serviços do “Cliente Parceiro”, e
        liquidado seu primeiro boleto de faturamento, lembrando que a parcela
        será liberada para saque após 05 dias do primeiro boleto pago, e a
        segunda parcela após 05 dias do segundo boleto pago, então será
        transferida para o Saldo de Resgate assim que houver a confirmação de
        tal liquidação junto ao banco, até então serão meramente indicativas, e
        não representam nenhum direito adquirido, uma vez que o processo ainda
        não foi finalizado.
      </Typography>
      <Typography>
        <strong>4.3</strong> O cronograma referente a inclusão de Promessa de
        Recompensa e de Saldo de Resgate, será da seguinte forma:
      </Typography>
      <Typography>
        Solicitação de Saque tem que ser realizada até o dia 10, para que o
        pagamento ocorra até o dia 15 do mesmo mês. Se solicitado após o dia 10,
        será pago no dia 15 do mês seguinte.
      </Typography>
      <Typography>
        • As datas acima, se forem em feriado ou final de semana, serão
        prorrogadas para o 1º dia útil seguinte.
      </Typography>
      <Typography>
        <strong>Exemplo:</strong>
      </Typography>
      <Typography>
        Solicitação de Saque realizada até dia 10/10/2021, pagamento ocorrerá
        dia 15/10/2021, se solicitação de Saque ocorrer após o dia 10/10/2021,
        pagamento ocorrerá até dia 15/11/2021:
      </Typography>
      <Typography>
        <strong>4.5</strong> O Saldo de Resgate estará acumulando crédito até
        que o Indiker realize a Solicitação de Resgate na própria Plataforma,
        sendo necessário um valor mínimo de R$150,00 (cento e cinquenta reais) a
        resgatar, que será creditado única e exclusivamente na conta
        corrente/poupança ou via Pix do próprio Indiker. Somente após o Indiker
        solicitar o resgate do saldo, que a Inovadig terá o compromisso de
        realizar o crédito.
      </Typography>
      <Typography>
        <strong>4.5.1</strong> Os valores apresentados tanto na Promessa de
        Recompensa quanto de Saldo de Resgate não estarão sujeitos à correção
        monetária por qualquer forma, e poderão ser excluídos, isto é, o Indiker
        não poderá mais adquirir o direito a receber se: a&#41; o Cliente
        Potencial não realizar o pagamento do boleto do seu 1º faturamento em
        até 90 dias do vencimento; b&#41; a Solicitação de Resgate não for
        realizada em até 365 dias, contados da data de transferência para o
        Saldo de Resgate.
      </Typography>
      <Typography>
        <strong>4.6</strong> Caso o Indiker entenda que ocorreu algum equívoco
        com a confirmação/crédito de sua Promessa de Recompensa ou com o Saldo
        de Resgate, poderá abrir um chamado junto ao suporte do IndikeGanhe
        através do e-mail:{' '}
        <a href="mailto:contato@indikeganhe.com.br">
          contato@indikeganhe.com.br
        </a>
      </Typography>
      <Typography>
        <strong>4.7</strong> Promoção Link de Compartilhamento consiste em que
        todo usuário que se cadastrar na Plataforma, realizar uma indicação de
        empresa e o ““Cliente Parceiro”” fechar contrato com a empresa indicada
        pelo Indiker, terá habilitado na Plataforma um link de compartilhamento.
        Novos Indikers que se cadastrarem através do link referido irão gerar um
        bônus de R$5 para o Indiker que cedeu o link, valor que será creditado
        no item Saldo de Resgate.
      </Typography>
      <Typography>
        Até o 25º cadastro realizado por um link de compartilhamento será gerado
        bônus ao Indiker. Podendo chegar a um prêmio de R$125,00 para o Indiker
        dono do Link de Compartilhamento.
      </Typography>
      <Typography className={classes.chapter}>
        5. DECLARAÇÕES DO INDIKER
      </Typography>
      <Typography>
        <strong>5.1</strong> O Indiker declara, sob pena de responder civil e
        criminalmente perante a Inovadig e/ou quaisquer terceiros que:
      </Typography>
      <Typography>
        <strong>5.1.1</strong> Tem ciência das obrigações e sanções legais
        aplicáveis à matéria de sigilo de informações, especialmente a Lei
        Complementar 105/2001, e manterá sigilo absoluto sobre as informações
        obtidas do Cliente Potencial e imputadas na Plataforma, permanecendo o
        dever de sigilo em vigor mesmo após o encerramento da participação ou do
        presente Programa;
      </Typography>
      <Typography>
        <strong>5.1.2</strong> Está ciente de que não é autorizado a prestar
        informações, declarações, ou manifestar-se sobre assuntos diversos ao
        público geral, de modo a criar aparência de ser um empregado ou
        representante legal da Inovadig; ou praticar atos fraudulentos, dolosos
        ou com evidente má-fé, afim de obter vantagem indevida; ou até mesmo
        identificar atos realizados com a finalidade de induzir ao erro para
        obter vantagem manifestamente ilícita;
      </Typography>
      <Typography>
        <strong>5.1.3</strong> Recebeu e tem permanente acesso ao material de
        consulta sobre as características dos Serviços prestados pela Inovadig
        aos Clientes Potenciais;
      </Typography>
      <Typography>
        <strong>5.1.4</strong> Está ciente das obrigações legais relacionadas à
        atividade de prevenção a crimes de “lavagem de dinheiro”, em especial
        das disposições previstas nas Leis 9.613/1998 e 12.683/2012;
      </Typography>
      <Typography>
        <strong>5.1.5</strong> É de sua responsabilidade a certificação de estar
        acessando sua conta em redes seguras, manter seu sistema protegido
        contra vírus e outros malwares, dessa forma a Inovadig não se
        responsabiliza por danos causados por vírus e malwares em decorrência de
        acesso, utilização ou navegação no site na internet ou como consequência
        da transferência de dados, arquivos, imagens, textos ou áudio contidos
        no mesmo;
      </Typography>
      <Typography>
        <strong>5.1.6</strong> Está ciente de que é expressamente vedada toda e
        qualquer publicação nas redes sociais em desacordo com a legislação
        vigente, com os bons costumes, ou ofensivo ao direito de qualquer pessoa
        que vincule a Inovadig, seus funcionários e produtos. Além disso, é
        vedada a prática SPAM ou qualquer tipo de atitudes contrárias às boas
        práticas da internet e das redes sociais que venha a afetar direta ou
        indiretamente a Inovadig, seus funcionários e produtos.
      </Typography>
      <Typography>
        <strong>5.1.7</strong> Não adotar, no âmbito da relação com a Inovadig,
        bem como seus representantes e funcionários, condutas desrespeitosas,
        ofensivas, difamatórias, obscenas, ilegais ou realizar ameaças;
      </Typography>
      <Typography className={classes.chapter}>
        6. AUTORIZAÇÕES DO INDIKER
      </Typography>
      <Typography>
        <strong>6.1</strong> O Indiker autoriza expressamente a Inovadig a: (a)
        coletar e armazenar seus dados pessoais; (b) consultar junto aos órgãos
        de restrição de cadastro ou outros bancos de dados públicos ou privados,
        as informações constantes de seu cadastro, reservando a Inovadig o
        direito de recusar seu cadastramento no Programa.
      </Typography>
      <Typography className={classes.chapter}>7. RESCISÃO</Typography>
      <Typography>
        <strong>7.1</strong> O presente instrumento é firmado por prazo
        indeterminado, e poderá ser rescindido por qualquer das partes, a
        qualquer tempo, e de forma imotivada, mediante simples comunicação pelos
        meios físicos ou eletrônicos com prazo mínimo de 15 dias.
      </Typography>
      <Typography>
        <strong>7.2</strong> As obrigações de pagamento pela Inovadig referentes
        ao Prêmio devido pelas indicações realizadas durante a vigência do
        presente, serão cumpridas até que finalizem.
      </Typography>
      <Typography>
        <strong>7.3</strong> O programa IndikeGanhe é mantido por prazo
        indeterminado, e poderá ser suspendido ou cancelado a qualquer momento,
        sem necessidade de notificação prévia, valendo apenas o informativo via
        Plataforma. Nessa ocasião, o Indiker terá um prazo de 30 dias a partir
        do informe para solicitar o Resgate do Saldo. Após esse prazo o Indiker
        não poderá mais adquirir o direito de resgate e a Inovadig não poderá
        ser responsabilizada por nenhum valor de reembolso indicado na conta do
        Indiker, cujo resgate não houver sido solicitado dentro do prazo.
      </Typography>
      <Typography className={classes.chapter}>
        8. DA PROTEÇÃO DOS DADOS
      </Typography>
      <Typography>
        <strong>8.1</strong> As Partes se comprometem a cumprir todas as leis,
        regras e regulamentos aplicáveis aos dados pessoais tratados em razão da
        execução do objeto deste Contrato, incluindo, mas não se limitando, a
        Lei 13.709/18 (Lei de Proteção de Dados Pessoais - LGPD).
      </Typography>
      <Typography>
        <strong>8.2</strong> o Indiker declara e garante que constituiu a base
        de dados de forma lícita em conformidade com a legislação vigente e que,
        conforme aplicável, possui autorização ou dá ciência aos titulares sobre
        o compartilhamento dos dados com a Inovadig e com os Clientes Parceiros,
        a depender da hipótese legal que autoriza o tratamento de dados
        realizado pelo Indiker.
      </Typography>
      <Typography>
        <strong>8.3</strong> O Indiker garante possuir política apropriada de
        proteção de dados pessoais compatível com todas as leis aplicáveis,
        incluindo, mas não se limitando, a adoção de medidas técnicas
        apropriadas para proteger os dados pessoais contra: (i) ameaças ou
        riscos à privacidade, à segurança, à integridade e/ou à
        confidencialidade; (ii) destruição acidental ou ilícita, perda,
        alteração, divulgação ou acesso não autorizado; (iii) quaisquer outras
        formas ilegais de tratamento; e (iv) incidentes de segurança ou
        privacidade.
      </Typography>
      <Typography>
        <strong>8.4</strong> O Indiker desde já concorda e autoriza
        expressamente que a Inovadig e/ou os Clientes Parceiros realizem a
        transferência internacional dos dados pessoais recebidos em razão deste
        Contrato, para empresas do mesmo grupo econômico ou prestadores de
        serviços contratados por estas.
      </Typography>
      <Typography>
        <strong>8.5</strong> Em caso de incidente de vazamento de dados
        pessoais, o Indiker deverá enviar comunicação à Inovadig, por escrito,
        no prazo máximo de 24 (vinte e quatro) horas contado a partir da ciência
        do vazamento, contendo, no mínimo, as seguintes informações:
      </Typography>
      <ol type="a">
        <Typography component="li">data e hora do incidente;</Typography>
        <Typography component="li">
          data e hora da ciência pelo Indiker;
        </Typography>
        <Typography component="li">
          relação dos tipos de dados afetados pelo incidente;
        </Typography>
        <Typography component="li">
          relação de titulares afetados pelo incidente; e
        </Typography>
        <Typography component="li">
          indicação de medidas que estiverem sendo tomadas para reparar
          eventuais danos e evitar novos incidentes.
        </Typography>
      </ol>
      <Typography className={classes.chapter}>9. DISPOSIÇÕES GERAIS</Typography>
      <Typography>
        <strong>9.1</strong> O presente Termo não garante a qualquer das partes
        preferência ou qualquer tipo de exclusividade, podendo o Indiker
        conduzir sua atividade de forma livre e autônoma, com absoluta
        independência.
      </Typography>
      <Typography>
        <strong>9.2</strong> O presente instrumento, sob nenhuma hipótese ou em
        qualquer situação, gerará vínculo empregatício entre Inovadig e Indiker,
        tão pouco acarretará obrigações de caráter trabalhista e previdenciário
        entre as partes, sendo que cada uma deverá realizar suas declarações
        fiscais.
      </Typography>
      <Typography>
        <strong>9.3</strong> O Indiker expressamente reconhece a validade de
        toda e qualquer comunicação/aviso que venha a ser realizada entre a
        Inovadig e ele, por qualquer meio eletrônico, reconhecendo, irrevogável
        e irretratavelmente, a força probante de tais comunicações/avisos
        perante qualquer juízo e/ou tribunal.
      </Typography>
      <Typography>
        <strong>9.4</strong> Fica estabelecido que, se qualquer das partes se
        abstiver de exercer direitos ou faculdades que pelo presente Programa
        lhe assistem ou se concordar com atrasos no cumprimento das obrigações a
        cargo da outra parte, não serão afetados aqueles direitos ou faculdades
        que poderão ser, pela parte inocente, exercidos em qualquer tempo a seu
        exclusivo critério, não implicando, aqueles atos de tolerância, em
        qualquer renúncia ou alteração das condições estatuídas neste Programa,
        nem obrigarão a parte inocente quanto a vencimentos e inadimplementos
        futuros.
      </Typography>
      <Typography>
        <strong>9.5</strong> Se, para a defesa de seus direitos decorrentes do
        presente Programa, ou para haver o que lhe for devido, a Inovadig
        necessitar recorrer a meios administrativos ou judiciais, terá ela
        direito ao ressarcimento, perante a parte inadimplente, das custas e
        despesas decorrentes, além dos honorários advocatícios incorridos, sendo
        que, em caso de cobrança judicial, os honorários advocatícios serão
        arbitrados judicialmente.
      </Typography>
      <Typography>
        <strong>9.6</strong> Ao aceitar o presente Termo, o Indiker manifesta
        expressamente a vontade de participar do Programa, aderindo
        expressamente às “Regras Gerais do Programa IndikeGanhe”, as quais se
        obriga, desde já, a observar e cumprir.
      </Typography>
      <Typography>
        <strong>9.7</strong> Esse Termo de Uso constitui documento jurídico
        integral e perfeito para regular a relação, e poderá ser modificado
        livremente e a qualquer tempo pela Inovadig. Dessa forma, será enviado
        um aviso ao Indiker informando sempre que ocorrerem mudanças, por sua
        vez, terá um prazo de 10 dias para manifestar sua não concordância aos
        novos Termos (o que poderá gerar a sua exclusão do Programa). Caso a
        manifestação não seja realizada durante esse período, a Inovadig poderá
        considerar, legitimamente, a concordância tácita aos novos Termos de
        Uso, que terão validade a partir da data em que forem veiculados no
        Plataforma/email do IndikeGanhe, reservando-se ao Indiker o direito de
        permanecer cadastrado ou simplesmente solicitar sua exclusão.
      </Typography>
      <Typography>
        <strong>9.8</strong> Todo o conteúdo (incluindo, mas não se limitando a,
        marcas, modelos, textos, programas de computador, sons, fotografias e
        outros materiais disponíveis na Plataforma da Inovadig), assim como a
        aparência, a organização e a estrutura do IndikeGanhe são protegidas
        pela legislação de propriedade intelectual e não infringem qualquer lei
        ou norma a que estejam subordinadas, contratos, documentos, acordos dos
        quais faz parte, bem como não infringem direitos de terceiros.
      </Typography>
      <Typography>
        <strong>9.9</strong> A violação de tais direitos ensejará a respectiva
        indenização aos prejudicados, sem prejuízo de perdas e danos e
        honorários advocatícios.
      </Typography>
      <Typography>
        <strong>9.10</strong> É vedado ao Indiker modificar, copiar, distribuir,
        transmitir, exibir, realizar, reproduzir, publicar, disponibilizar,
        licenciar ou criar obras derivadas a partir das informações coletadas na
        Plataforma, bem como transferir ou vender tais informações, software,
        produtos ou serviços, sob pena de violação do presente termo e infração
        legal.
      </Typography>
      <Typography>
        <strong>9.11</strong> Havendo algum conflito de informações constantes
        neste Termo de Uso com informações constantes em outros documentos,
        prevalecerão sempre os termos deste Termo, que é o documento formal
        eleito para reger a relação entre Inovadig e Indiker.
      </Typography>
      <Typography>
        <strong>9.12</strong> Fica eleito como competente para conhecer e
        dirimir quaisquer dúvidas ou questões que, porventura, venham a decorrer
        deste Termo, o foro da comarca de Gravataí/RS, com renúncia de todos os
        outros, por mais privilegiados que sejam.
      </Typography>
    </div>
  );
};

export default Terms;
