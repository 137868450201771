import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '12px',
    textTransform: 'uppercase',

    '& .status-container': {
      display: 'flex',
      marginBottom: '4px',

      '& .circle': {
        minWidth: 8,
        minHeight: 8,
        borderRadius: '50%',
        background: '#E3E3E3',
        margin: '0 2px',
      },
    },
  },
});
