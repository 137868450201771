import React from 'react';
import { RouteProps, Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import { useAuth } from '../context/auth';
import Layout from '../components/Layout/index';

interface RouteInterface extends RouteProps {
  isPrivate?: boolean;
  noLayout?: boolean;
  noRestriction?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteInterface> = ({
  isPrivate = false,
  noLayout,
  noRestriction,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (noRestriction) {
          return <Component />;
        }

        return isPrivate === !!user ? (
          <>
            {noLayout || !isPrivate ? (
              <Component />
            ) : (
              <Layout>
                <Component />
              </Layout>
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/login' : '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
