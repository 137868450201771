import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from './styles';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';
import api from '../../services/api';
import { emailValidator } from '../../utils/validators';
import BackLink from '../../components/BackLink/index';

interface FormData {
  email: string;
}

const initialValues: FormData = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .test('Email', 'E-mail inválido', (value) => emailValidator(value)),
});

const ForgotPassword: React.FC = () => {
  const { addToast } = useToast();
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const history = useHistory();
  const classes = useStyles();

  const handleSubmit = useCallback(
    async (values: FormData) => {
      startLoading();
      try {
        await api.post('/api/auth/forgot-password', values);
        addToast({
          message: 'E-mail enviado com éxito',
          type: 'success',
        });
        history.push('/redefinir-senha', { email: values.email });
        endLoading();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        showApiError(error, 'Erro processando sua solicitação');
        endLoading();
      }
    },
    [startLoading, addToast, history, endLoading, showApiError]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className={classes.root}>
      <BackLink floating color="dark" />
      <Card className={classes.container}>
        <Typography component="div" className={classes.title}>
          - Esqueci minha senha!
        </Typography>
        <Typography component="div" className={classes.text}>
          Ok! Vamos enviar o código de recuperação para seu{' '}
          <strong>e-mail cadastrado no IndikeGanhe</strong>, beleza?
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="email"
            name="email"
            label="Seu e-mail"
            className={classes.field}
            variant="outlined"
            fullWidth
            error={formik.touched.email && !!formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={(formik.touched.email && formik.errors.email) || null}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
          >
            Enviar
          </Button>
        </form>
      </Card>
      <div className={classes.footer}>
        <div className={classes.footerContainer}>
          <Typography component="div" className={classes.footerText}>
            Caso você não possua mais acesso ao seu email, entre em contato com
            o nosso atendimento através do (E-mail) ou (telefone)
          </Typography>
          <Typography component="div" className={classes.footerCopyright}>
            Direitos reservados © IndikeGanhe
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
