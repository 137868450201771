import React from 'react';
import { Typography } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';
import { Field } from 'formik';

const ContactName: React.FC = () => {
  return (
    <>
      <Typography className="title">
        Com quem nossos consultores vão conversar?
      </Typography>
      <div className="input">
        <Typography className="label">
          Nome do seu contato dentro da empresa.
        </Typography>
        <Field
          name="contactName"
          component={(props: TextFieldProps) => (
            <TextField
              {...props}
              inputProps={{
                ref: (element: HTMLInputElement) => {
                  if (element) element.focus();
                },
              }}
            />
          )}
          fullWidth
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
    </>
  );
};

export default ContactName;
