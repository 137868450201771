import React, { createContext, useContext, useCallback, useState } from 'react';

import api from '../services/api';
import { StateDTO, CityDTO } from '../dtos/AddressDTO';

interface ContextData {
  states: StateDTO[];
  getStates(): Promise<void>;
  resetStates(): void;
  cities: CityDTO[];
  getCities(stateId: number): Promise<void>;
  resetCities(): void;
}

const LocalizationContext = createContext<ContextData>({} as ContextData);

const LocalizationProvider: React.FC = ({ children }) => {
  const [states, setStates] = useState<StateDTO[]>([]);
  const [cities, setCities] = useState<CityDTO[]>([]);

  const getStates = useCallback(async () => {
    if (states.length === 0) {
      const result = await api.get<StateDTO[]>('/api/state');
      setStates(
        result.data.sort((a, b) =>
          a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase())
        )
      );
    }
  }, [states]);

  const resetStates = useCallback(() => {
    setStates([]);
  }, []);

  const getCities = useCallback(async (stateId: number) => {
    const result = await api.get<CityDTO[]>(`/api/state/${stateId}/cities`);
    setCities(
      result.data.sort((a, b) =>
        a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase())
      )
    );
  }, []);

  const resetCities = useCallback(() => {
    setCities([]);
  }, []);

  return (
    <LocalizationContext.Provider
      value={{
        states,
        getStates,
        getCities,
        cities,
        resetStates,
        resetCities,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

const useLocalization = (): ContextData => {
  const context = useContext(LocalizationContext);

  if (!context) {
    throw new Error(
      'useLocalization must be used within a LocalizationProvider'
    );
  }

  return context;
};

export { LocalizationProvider, useLocalization };
