import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Dialog,
  Paper,
  Typography,
} from '@material-ui/core';
import { MdSend } from 'react-icons/md';

import { useStyles } from './styles';
import { ReactComponent as IndikeCoin } from '../../assets/earnings.svg';
import Tooltip from '../../components/Tooltip/index';
import { useAuth } from '../../context/auth';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';
import api from '../../services/api';
import { getCurrencyBr } from '../../utils/format';

interface WithdrawProps {
  withdraw: number;
  onUpdate: () => void;
}

const Withdraw: React.FC<WithdrawProps> = ({ withdraw, onUpdate }) => {
  const [noBankAccountOpen, setNoBankAccountOpen] = useState(false);
  const { user } = useAuth();
  const history = useHistory();
  const { startLoading, endLoading } = useLoading();
  const { showApiError } = useErrorHandle();
  const { addToast } = useToast();
  const classes = useStyles();

  const handleRequestWithdraw = useCallback(async () => {
    if (!user.bank_account_id) {
      setNoBankAccountOpen(true);
      return;
    }

    startLoading();
    api
      .post('/api/withdraw-payments')
      .then(() => {
        onUpdate();
        addToast({
          message: 'Saque solicitado com sucesso',
          type: 'success',
        });
      })
      .catch((error) => {
        showApiError(error, 'Erro solicitando o saque');
      })
      .finally(() => {
        endLoading();
      });
  }, [
    addToast,
    endLoading,
    onUpdate,
    showApiError,
    startLoading,
    user.bank_account_id,
  ]);

  return (
    <>
      <Paper className={classes.container}>
        <Typography component="div" className={classes.title}>
          Meus Ganhos
          <Tooltip>
            <p>
              <strong>Aqui</strong> você confere o{' '}
              <strong>
                valor total (R$) de todas suas indicações e bonificações
              </strong>{' '}
              que ainda estão <strong>disponíveis para saque</strong>.
            </p>
            <p>
              <strong>Quando</strong> você <strong>atingir</strong> um{' '}
              <strong>
                valor igual ou maior que R$150 o botão &quot;Sacar&quot; ficará
                liberado
              </strong>
              .
            </p>
            <p>
              <strong>Após</strong> você <strong>solicitar o saque</strong>,
              poderá{' '}
              <strong>
                acompanhar seus ganhos no &quot;Gráfico de ganhos&quot;
              </strong>{' '}
              e também no <strong>&quot;Histórico de Saques&quot;</strong>{' '}
              contidos nesta tela.
            </p>
          </Tooltip>
        </Typography>
        <Card className={classes.currentMonth}>
          <IndikeCoin />
          <Box className="earnings-container">
            <Typography className="earnings-text">
              Total disponível para saque
            </Typography>
            <Typography className="month-earnings">
              {getCurrencyBr(withdraw)}
            </Typography>
          </Box>
        </Card>
        <Box className={classes.sendButton}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={withdraw < 150}
            onClick={handleRequestWithdraw}
          >
            Sacar
            <MdSend />
          </Button>
        </Box>
        <Typography className={classes.minimalWithdraw}>
          *Saque mínimo de R$ 150,00
        </Typography>
      </Paper>
      <Dialog
        open={noBankAccountOpen}
        onClose={() => setNoBankAccountOpen(false)}
        className={classes.noBankAccountDialog}
      >
        <Typography>
          Para solicitar saque, deve primeiro cadastrar um PIX ou conta de banco
        </Typography>
        <Box className="btnContainer">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setNoBankAccountOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/perfil/conta', { origin: '/ganhos' })}
          >
            Cadastrar
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default Withdraw;
