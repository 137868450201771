import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Routes';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Registration from '../pages/Registration';
import Welcome from '../pages/Welcome';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Indications from '../pages/Indications';
import Indication from '../pages/Indication';
import Indicate from '../pages/Indicate';
import StartIndicate from '../pages/Indicate/Start';
import EndIndicate from '../pages/Indicate/End';
import Earnings from '../pages/Earnings';
import Profile from '../pages/Profile';
import ProfileEdit from '../pages/Profile/ProfileEdit';
import Help from '../pages/Help';
import HelpView from '../pages/Help/HelpView';
import PublicHelp from '../pages/Help/PublicHelp';
import PublicHelpView from '../pages/Help/PublicHelpView';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import EmailConfirmation from '../pages/EmailConfirmation';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" component={Home} exact isPrivate />
    <Route path="/login" component={Login} exact />
    <Route path="/cadastro" component={Registration} exact />
    <Route path="/welcome" component={Welcome} exact isPrivate noLayout />
    <Route path="/forgot_password" component={ForgotPassword} exact />
    <Route path="/redefinir-senha" component={ResetPassword} exact />
    <Route path="/indicacoes" component={Indications} isPrivate />
    <Route path="/indicacao" component={Indication} isPrivate />
    <Route path="/indicar" component={StartIndicate} isPrivate noLayout />
    <Route path="/nova-indicacao" component={Indicate} isPrivate noLayout />
    <Route
      path="/indicacao-concluida"
      component={EndIndicate}
      isPrivate
      noLayout
    />
    <Route path="/ganhos" component={Earnings} isPrivate />
    <Route path="/perfil" component={Profile} exact isPrivate />
    <Route path="/perfil/:section" component={ProfileEdit} isPrivate />
    <Route path="/ajuda" component={Help} exact isPrivate />
    <Route path="/ajuda/:page" component={HelpView} isPrivate />
    <Route
      path="/duvidas-frequentes"
      component={PublicHelp}
      exact
      noRestriction
    />
    <Route
      path="/duvidas-frequentes/:page"
      component={PublicHelpView}
      noRestriction
    />
    <Route path="/termos-de-uso" component={Terms} exact noRestriction />
    <Route
      path="/politica-de-privacidade"
      component={Privacy}
      exact
      noRestriction
    />
    <Route
      path="/confirmacao-de-email/:id/:hash"
      component={EmailConfirmation}
      exact
      noRestriction
    />
  </Switch>
);

export default Routes;
