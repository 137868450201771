import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import HelpView from './HelpView';
import Footer from '../partials/Footer';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 70,
  },
});

/**
 * Visualização da ajuda com acesso público
 */
const PublicHelpView: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <HelpView />
      </div>
      <Footer />
    </>
  );
};

export default PublicHelpView;
