import React from 'react';

import { useStyles } from './styles';
import Header from '../../pages/partials/Header/index';
import Footer from '../../pages/partials/Footer';

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <div className={classes.root}>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
