import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { LocalizationProvider } from './localization';
import { IndicationStatusProvider } from './indicationStatus';
import { TutorialProvider } from './tutorial';
import { LegalProvider } from './legal';
import { LoadingProvider } from './loading';
import { ErrorHandleProvider } from './errorHandle';

const Providers: React.FC = ({ children }) => {
  return (
    <>
      <LoadingProvider>
        <AuthProvider>
          <IndicationStatusProvider>
            <LocalizationProvider>
              <ToastProvider>
                <ErrorHandleProvider>
                  <LegalProvider>
                    <TutorialProvider>{children}</TutorialProvider>
                  </LegalProvider>
                </ErrorHandleProvider>
              </ToastProvider>
            </LocalizationProvider>
          </IndicationStatusProvider>
        </AuthProvider>
      </LoadingProvider>
    </>
  );
};

export default Providers;
