import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.container}>
        <Typography component="div" className={classes.text}>
          Direitos reservados © <Link to="/">IndikeGanhe</Link>
        </Typography>
      </Paper>
    </div>
  );
};

export default Header;
