import React, { createContext, useCallback, useContext, useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface LoadingContextData {
  startLoading(): void;
  endLoading(): void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 5000,
    backgroundColor: theme.palette.primary.main,
    '& .MuiCircularProgress-root': {
      color: theme.palette.background.paper,
    },
  },
}));

const LoadingContext = createContext({} as LoadingContextData);

const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const startLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const endLoading = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <LoadingContext.Provider value={{ startLoading, endLoading }}>
      {children}
      <Backdrop open={loading} className={classes.root}>
        <CircularProgress color="primary" size={100} />
      </Backdrop>
    </LoadingContext.Provider>
  );
};

const useLoading = (): LoadingContextData => {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  return context;
};

export { LoadingProvider, useLoading };
