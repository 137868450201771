import React from 'react';
import { Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

const Name: React.FC = () => {
  return (
    <>
      <Typography className="title">Qual o seu nome?</Typography>
      <div className="input">
        <Typography className="label">Insira seu nome.</Typography>
        <Field
          name="firstname"
          component={TextField}
          fullWidth
          autoFocus
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
      <div className="input">
        <Typography className="label">Insira seu sobrenome.</Typography>
        <Field
          name="lastname"
          component={TextField}
          fullWidth
          placeholder="Escreva sua resposta aqui..."
        />
      </div>
    </>
  );
};

export default Name;
