/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextField, TextFieldProps } from 'formik-material-ui';

interface MaskedFieldProps extends TextFieldProps {
  component: any;
  autoFocus?: boolean;
}

const MaskedField: React.FC<MaskedFieldProps> = ({
  component,
  autoFocus,
  ...props
}) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: component,
        ref: autoFocus
          ? (element: HTMLDivElement) => {
              if (element) {
                const input = element.querySelector('input');
                input?.focus();
              }
            }
          : undefined,
      }}
    />
  );
};

export default MaskedField;
