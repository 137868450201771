import axios, { AxiosError } from 'axios';

import ApiError from '../utils/ApiError';

interface ApiErrorResponse {
  message: string;
  errors?: {
    [key: string]: string[];
  };
}

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({ baseURL });

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@IndikeGanhe:token');

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (process.env.NODE_ENV === 'development') {
    return new Promise((resolve) => setTimeout(() => resolve(config), 2000));
  }
  return config;
});

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError<ApiErrorResponse>) => {
    console.error('error', error.toJSON());
    const data = error.response ? error.response.data : error;
    const status = error.response?.status;
    return Promise.reject(new ApiError(data, status));
  }
);

export default api;
