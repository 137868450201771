import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Button, Dialog, TextField, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { SubmitProps } from './props';
import { useAuth } from '../../context/auth';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';
import api from '../../services/api';

interface ConfirmationProps extends SubmitProps {
  open: boolean;
  onClose: () => void;
}

const timerLength = 60;

const EditConfirmation: React.FC<ConfirmationProps> = ({
  url,
  method,
  data,
  successMessage,
  errorMessage,
  open,
  onClose,
}) => {
  const [code, setCode] = useState('');
  const [timeLeft, setTimeLeft] = useState(timerLength);
  const { getUser } = useAuth();
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const { addToast } = useToast();
  const history = useHistory();
  const classes = useStyles();

  const sendEmailConfirmation = useCallback(async () => {
    setTimeLeft(timerLength);
    startLoading();
    try {
      await api.post('/api/user-validation');
    } catch (error) {
      showApiError(error, 'Erro validando o usuário');
    }
    endLoading();
  }, [endLoading, showApiError, startLoading]);

  useEffect(() => {
    if (open) {
      setTimeLeft(timerLength);
      sendEmailConfirmation();
    }
    return () => setCode('');
  }, [open, setCode, sendEmailConfirmation]);

  useEffect(() => {
    if (open && timeLeft > 0) {
      setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }
  }, [open, timeLeft]);

  const handleSubmit = useCallback(() => {
    Object.assign(data, { validation_code: code });

    startLoading();
    api[method](url, data)
      .then(() => {
        getUser();
        addToast({ message: successMessage, type: 'success' });
        history.push('/perfil');
      })
      .catch((error) => {
        showApiError(error, errorMessage);
      })
      .finally(() => endLoading());
  }, [
    addToast,
    code,
    data,
    endLoading,
    errorMessage,
    getUser,
    history,
    method,
    showApiError,
    startLoading,
    successMessage,
    url,
  ]);

  return (
    <>
      <Dialog open={open} onClose={onClose} className={classes.modalContainer}>
        <Typography className="text">
          Enviamos um código de verificação para seu e-mail cadastrado no
          IndikeGanhe.
        </Typography>
        <Typography className="action-text">Verifique seu e-mail</Typography>
        <TextField
          id="code"
          name="code"
          label="Código de verificação"
          className={classes.field}
          variant="outlined"
          fullWidth
          autoComplete="new-password"
          value={code}
          onChange={(ev) => setCode(ev.currentTarget.value)}
        />
        <Box className="resend-container">
          <Typography component="span" className="resend-message">
            Não recebeu o código?
          </Typography>
          <Button
            type="button"
            color="primary"
            className="resend-button"
            disabled={timeLeft > 0}
            onClick={sendEmailConfirmation}
          >
            Reenviar
          </Button>
          <Typography component="span" className="activation-timer">
            ({timeLeft}s)
          </Typography>
        </Box>
        <Box marginTop={3} textAlign="center">
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={code.length !== 6}
            onClick={handleSubmit}
          >
            Confirmar
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default EditConfirmation;
