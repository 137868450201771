import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const End: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.text}>
          <strong>Parabéns.</strong>
          <br />
          Você fez uma indicação!
        </Typography>
        <Button
          variant="contained"
          className={classes.btnFilled}
          fullWidth
          size="large"
          onClick={() => history.push('/')}
        >
          ir para o painel
        </Button>
        <Button
          variant="outlined"
          className={classes.btnOutlined}
          fullWidth
          size="large"
          onClick={() => history.push('/nova-indicacao')}
        >
          fazer outra indicação
        </Button>
      </div>
    </div>
  );
};

export default End;
