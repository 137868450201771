import React, { useCallback, useEffect, useState } from 'react';
import { Grid, useMediaQuery, Theme } from '@material-ui/core';

import { useStyles } from './styles';
import Earnings from './Earnings';
import CallToAction from './CallToAction';
import IndicationsTable from './IndicationsTable';
import ShareLink from './ShareLink';

import LowerCta from '../../assets/lower_cta.png';
import LowerCtaDesktop from '../../assets/lower_cta_desktop.png';
import api from '../../services/api';
import { useAuth } from '../../context/auth';
import { useErrorHandle } from '../../context/errorHandle';
import { useLoading } from '../../context/loading';
import { useToast } from '../../context/toast';
import { ListDTO } from '../../dtos/ResultsDTO';
import { IndicationDTO } from '../../dtos/IndicationDTO';

const Home: React.FC = () => {
  const [indications, setIndications] = useState<IndicationDTO[]>([]);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const { user } = useAuth();
  const { showApiError } = useErrorHandle();
  const { startLoading, endLoading } = useLoading();
  const { addToast } = useToast();
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const getIndications = useCallback(async () => {
    try {
      const result = await api.get<ListDTO<IndicationDTO>>(
        '/api/indication?limit=5'
      );
      setIndications(result.data.data);
    } catch (error) {
      showApiError(error, 'Erro obtendo a lista de indicações');
    }
  }, [showApiError]);

  const getTotalAvailable = useCallback(async () => {
    try {
      const result = await api.get<{ total: number }>(
        '/api/payment-reports/total-available'
      );
      setTotalAvailable(result.data.total);
    } catch (error) {
      showApiError(error, 'Erro obtendo o saldo a receber');
    }
  }, [showApiError]);

  useEffect(() => {
    startLoading();
    Promise.all([getIndications(), getTotalAvailable()]).finally(() => {
      endLoading();
    });
  }, [addToast, startLoading, endLoading, getIndications, getTotalAvailable]);

  return (
    <div className={classes.container}>
      <Grid container spacing={matches ? 3 : 0}>
        <Grid item xs={12} lg={8}>
          {matches ? <CallToAction /> : <Earnings amount={totalAvailable} />}
        </Grid>
        <Grid item xs={12} lg={4}>
          {matches ? <Earnings amount={totalAvailable} /> : <CallToAction />}
        </Grid>
        <Grid item xs={12} lg={8}>
          <IndicationsTable data={indications} />
        </Grid>
        {/*  <Grid item xs={12} lg={4}>
          <ShareLink
            link={user.link_share_url}
            can_share={user.can_share_link}
          />
        </Grid> */}
        <Grid item xs={12}>
          <img
            src={matches ? LowerCtaDesktop : LowerCta}
            alt="CTA"
            style={{ width: '100%', borderRadius: 5 }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
