import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      width: 382,
      maxWidth: '95%',
      maxHeight: '90vh',
      overflowY: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: 600,
      },
      color: theme.palette.text.primary,
      backgroundColor: '#FFFFFF',
      opacity: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 25,
    },
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
  },
  closeBtn: {
    marginTop: 20,
    '& .MuiButton-label': {
      fontSize: 16,
      color: theme.palette.background.paper,
      '& svg': {
        marginLeft: 10,
      },
    },
  },
  container: {
    overflowX: 'hidden',
    width: '100%',
    padding: '30px 10px',
    '& .MuiTypography-root': {
      marginBottom: 5,
    },
  },
}));
