import React from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { Button, Grid, Typography } from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import { LogonFormData } from './props';
import { useLocalization } from '../../context/localization';
import { useLegal } from '../../context/legal';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.background.paper,
    '& .MuiIconButton-root': {
      color: theme.palette.background.paper,
    },
  },
  title: {
    fontSize: '25px',
    fontWeight: 600,
    marginBottom: '20px',
  },
  label: {
    fontSize: '15px',
    color: '#85B0FF',
    marginBottom: '15px',
  },
  value: {
    fontSize: '15px',
    fontWeight: 600,
  },
  field: {
    paddingTop: 25,
    width: '100%',
  },
  fieldError: {
    fontSize: '12px',
    color: theme.palette.error.main,
  },
  confirmation: {
    '& .MuiButton-root': { minWidth: 'unset' },
    '& .MuiButton-text': {
      padding: 0,
    },
    '& .MuiButton-label': {
      color: '#fff',
      fontSize: '1em',
    },
  },
}));

const Confirm: React.FC = () => {
  const { values } = useFormikContext<LogonFormData>();
  const { states, cities } = useLocalization();
  const { openPrivacy, openTerms } = useLegal();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Confirme seus dados</Typography>
      <Grid container className="text">
        <Grid item xs={4}>
          <Typography id="firstname" className={classes.label}>
            Nome:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography id="firstname" className={classes.value}>
            {values.firstname}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography id="lastname" className={classes.label}>
            Sobrenome:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography id="lastname" className={classes.value}>
            {values.lastname}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography id="email" className={classes.label}>
            E-mail:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography id="email" className={classes.value}>
            {values.email}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography id="document" className={classes.label}>
            CPF:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography id="document" className={classes.value}>
            {values.document}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography id="city" className={classes.label}>
            Cidade:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography id="city" className={classes.value}>
            {
              cities.filter((item) => item.id === parseInt(values.city, 10))[0]
                .title
            }
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography id="state" className={classes.label}>
            Estado:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography id="state" className={classes.value}>
            {
              states.filter((item) => item.id === parseInt(values.state, 10))[0]
                .title
            }
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.field}>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="accepted_terms"
          />
          <Typography component="span" className={classes.confirmation}>
            Confirmo que aceito os{' '}
            <Button type="button" variant="text" onClick={() => openTerms()}>
              termos
            </Button>{' '}
            e a{' '}
            <Button type="button" variant="text" onClick={() => openPrivacy()}>
              declaração de privacidade
            </Button>
            !{' '}
          </Typography>
          <ErrorMessage
            component="span"
            name="accepted_terms"
            className={classes.fieldError}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Confirm;
