import { Button, Hidden, List, Typography } from '@material-ui/core';
import React from 'react';
import { CgFileDocument, CgHome } from 'react-icons/cg';

import { useStyles } from './styles';
import MenuItem from './MenuItem';
import Avatar from '../../../components/Avatar';
import { useAuth } from '../../../context/auth';
import { fullName } from '../../../utils/format';
import { ReactComponent as IconIndicar } from '../../../assets/icon_indicar.svg';
import { ReactComponent as IconFaq } from '../../../assets/icon_faq.svg';
import { ReactComponent as IconMoney } from '../../../assets/icon_money.svg';
import { ReactComponent as IconUser } from '../../../assets/icon_user.svg';

interface MenuContentProps {
  onClose: () => void;
}

const MenuContent: React.FC<MenuContentProps> = ({ onClose }) => {
  const classes = useStyles();
  const { user, signOut } = useAuth();

  return (
    <div className={classes.menuContainer}>
      <Hidden mdUp>
        <div className={classes.avatarContainer}>
          <Avatar size={72} />
          <Typography component="div" className={classes.name}>
            {fullName(user.firstname, user.lastname)}
          </Typography>
        </div>
      </Hidden>
      <List className={classes.list}>
        <MenuItem
          destination="/"
          icon={CgHome}
          title="Home"
          onClose={onClose}
        />
        <MenuItem
          destination="/indicar"
          icon={IconIndicar}
          title="Indicar"
          onClose={onClose}
        />
        <MenuItem
          destination="/indicacoes"
          icon={CgFileDocument}
          title="Indicações"
          onClose={onClose}
        />
        <MenuItem
          destination="/ganhos"
          icon={IconMoney}
          title="meus ganhos"
          onClose={onClose}
        />
        <MenuItem
          destination="/perfil"
          icon={IconUser}
          title="Perfil"
          onClose={onClose}
        />
        <MenuItem
          destination="/ajuda"
          icon={IconFaq}
          title="Me ajuda"
          onClose={onClose}
        />
      </List>
      <div className={classes.btnContainer}>
        <Button fullWidth variant="contained" color="primary" onClick={signOut}>
          Sair
        </Button>
      </div>
    </div>
  );
};

export default MenuContent;
