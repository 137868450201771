import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { FaChevronLeft } from 'react-icons/fa';

import { useStyles } from './styles';

interface BackLinkProps {
  floating?: boolean;
  color?: 'dark' | 'light';
}

const BackLink: React.FC<BackLinkProps> = ({ floating, color }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box className={floating ? 'floating-btn' : 'relative-btn'}>
        <Button onClick={history.goBack}>
          <FaChevronLeft size={22} />
          <Typography
            component="span"
            className={color === 'dark' ? 'dark-btn' : 'light-btn'}
          >
            voltar
          </Typography>
        </Button>
      </Box>
    </div>
  );
};

export default BackLink;
