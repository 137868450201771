import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as LogoPlus } from '../../assets/icon_indicar.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 20px 35px',
    position: 'relative',
    overflow: 'visible',
    marginBottom: 50,
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      margin: 0,
    },
  },
  logoContainer: {
    position: 'absolute',
    top: -25,
    left: -40,
    width: 'auto',
    height: '130%',
  },
  logo: {
    fill: '#E5E5E5',
    height: '100%',
    width: 'auto',
  },
  textContainer: {
    zIndex: 50,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 200,
      marginRight: 30,
    },
  },
  title: {
    fontSize: '30px',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      lineHeight: 1,
    },
  },
  text: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
    '& span': {
      fontWeight: 600,
    },
  },
  ctaButtonContainer: {
    position: 'absolute',
    bottom: '-22px',
    left: 0,
    right: 0,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      position: 'static',
    },
  },
  ctaButton: {
    margin: '0',
    padding: '8px 20px',
    fontSize: '14px',
    fontWeight: 700,

    '& .MuiButton-label': {
      [theme.breakpoints.up('lg')]: {
        fontSize: 25,
      },
    },
    '& .ctaBtnIcon': {
      [theme.breakpoints.up('lg')]: {
        fontSize: 45,
      },
    },
    '& svg': {
      marginRight: 10,
      '& .a': {
        fill: '#fff',
      },
    },
  },
}));

const CallToAction: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Hidden mdDown>
        <div className={classes.logoContainer}>
          <Logo className={classes.logo} />
        </div>
      </Hidden>
      <div className={classes.textContainer}>
        <Typography component="div" className={classes.title}>
          indique{' '}
          <Hidden lgUp>
            <br />
          </Hidden>
          uma empresa
        </Typography>
        <Typography component="div" className={classes.text}>
          Para indicar, você precisa apenas de seis dados.{' '}
          <span>Comece agora!</span>
        </Typography>
      </div>
      <div className={classes.ctaButtonContainer}>
        <Button
          variant="contained"
          type="button"
          color="secondary"
          className={classes.ctaButton}
          onClick={() => history.push('/indicar')}
        >
          <div className="ctaBtnIcon">
            <LogoPlus />
          </div>
          Indicar
        </Button>
      </div>
    </Card>
  );
};

export default CallToAction;
