import React from 'react';
import { Drawer, Hidden, SwipeableDrawer } from '@material-ui/core';

import { useStyles } from './styles';
import MenuContent from './MenuContent';

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp>
          <SwipeableDrawer
            anchor="left"
            open={isOpen}
            onClose={onClose}
            onOpen={() => {}}
          >
            <MenuContent onClose={onClose} />
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown>
          <Drawer variant="permanent" open className={classes.permanentDrawer}>
            <MenuContent onClose={onClose} />
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export default Menu;
